import React from 'react';
import './App.css';
import './Intro.css';

function Picture() {
  return (
    <div className="picture-container">
      <img src={require('./profile_picture.jpg')}
        className="profile-picture"
        alt="Ovidio Ruiz-Henríquez"></img>
    </div>
  )
}

function Intro() {
  return (
    <div className="intro">
      <div className="column-container">
        <h1>Welcome!</h1>
        <div className="column-container">
          <div className="row-container">
            <div className="row-item">
              <Picture></Picture>
            </div>
            <div className="row-item">
              <p>
                I am Ovidio Ruiz-Henríquez, a computer engineer from Houston, Texas
                and currently living in Austin. I graduated from Texas A&M
                University with a B.S. in Computer Engineering with a minor in
                Mathematics and started my career at Google. I have over 6 years
                of experience in creating designs and specifications for new Web
                APIs and implementing them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
