import React from 'react';
import Intro from './Intro';
import Details from './Experience';
import Community from './Community';
import SocialLinks from './SocialLinks';
import './App.css';

function App() {
  return (
    <div className="grid-container">
      <Intro />
      <Details />
      <Community />
      <SocialLinks />
    </div>
  );
}

export default App;
