import React from 'react';

export interface DateDetails {
  month?: number;
  year: number;
  season?: string;
}

function monthNumToText(num: number) {
  switch (num) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'Jul';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return 'Present';
  }
}

interface DateAttr {
  date: DateDetails | undefined;
  length?: number;
}
function Date({ date, length }: DateAttr) {
  if (!date) {
    return <span className="date">Present</span>;
  }
  if (date.month) {
    let month = monthNumToText(date.month);
    if (length) {
      month = month.slice(0, length);
    }
    return (
      <span className="date">
        {month} {date.year}
      </span>
    );
  }
  if (date.season) {
    return <span className="date">{date.season} {date.year}</span>
  }
  return <></>
}

export interface DatesAttr {
  startDate: DateDetails;
  endDate: DateDetails | undefined;
}
export function Dates({ startDate, endDate }: DatesAttr) {
  if (startDate.season) {
    let endDateHTML = <></>
    if (endDate) {
      endDateHTML = (
        <>
          <span className="date-divider"> - </span>
          <Date date={endDate} />
        </>)
    }
    return (
      <span className="dates">
        <Date date={startDate} />
        {endDateHTML}
      </span>
    )
  }
  return (
    <span className="dates">
      <Date date={startDate} length={3} />
      <span className="date-divider"> - </span>
      <Date date={endDate} length={3} />
    </span>
  );
}