import React from 'react';
import './App.css';

// function LinkedInBadge() {
//   const linkedInBadge =
//     '<div class="badge-base LI-profile-badge" data-locale="en_US"' +
//     ' data-size="medium" data-theme="dark" data-type="VERTICAL"' +
//     ' data-vanity="ovidiodruizhenriquez" data-version="v1"></div>';
//   return (<div
//     className="badge row-item"
//     dangerouslySetInnerHTML={{ __html: linkedInBadge }}
//   ></div>);
// }

// function GitHubBadge() {
//   const gitHubBadge =
//     '<div class="github-card" data-github="odejesush" data-width="400"' +
//     ' data-height="150" data-theme="default">' +
//     '<a href="https://github.com/odejesush">GitHub</a></div>';
//   return (
//     <div
//       className="badge row-item"
//       dangerouslySetInnerHTML={{ __html: gitHubBadge }}
//     ></div>
//   );
// }

function SocialLinks() {
  return (
    <div className="column-item">
      <div className="row-container">
        <a
          className="row-item"
          href="https://linkedin.com/in/ovidiodruizhenriquez"
        >
          LinkedIn
        </a>
        <a className="row-item" href="https://github.com/odejesush">
          GitHub
        </a>
      </div>
    </div>
  );
}

export default SocialLinks;