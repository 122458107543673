import React, { forwardRef } from 'react';
import './App.css';
import './ExpandableDescription.css';

interface ExpandableDescriptionAttr {
  shortDescription: string;
  achievements: string[];
}
const ExpandableDescription = forwardRef(({ shortDescription, achievements }: ExpandableDescriptionAttr, ref: React.Ref<HTMLDivElement>) => {
  return (
    <div>
      <div className="short-description">
        <p>{shortDescription}</p>
      </div>

      <div ref={ref} className="full-description">
        <ul>
          {achievements.map((item) => {
            return <li className="achievement">{item}</li>;
          })}
        </ul>
      </div>
    </div>
  );
});

export default ExpandableDescription;