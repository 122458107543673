import React, { useState } from 'react';
import { Dates, DateDetails } from './Dates';
import ExpandableDescription from './ExpandableDescription';
import './App.css';
import './Experience.css';

interface LocationDetails {
  city: string;
  state: string;
}

interface WorkExperience {
  jobTitle: string;
  jobLevel: string;
  company: string;
  startDate: DateDetails;
  endDate?: DateDetails;
  location: LocationDetails;
  team?: string;
  shortDescription: string;
  achievements: string[];
}

const data: WorkExperience[] = require('./experience.json');

interface LocationAtrr {
  location: LocationDetails;
}
function Location({ location }: LocationAtrr) {
  return (
    <span className="location">
      {location.city}, {location.state}
    </span>
  );
}

interface JobTitleAttr {
  title: string;
  level: string;
}
function JobTitle({ title, level }: JobTitleAttr) {
  return (
    <span className="job-title">{title} {level}</span>
  );
}

interface TeamAttrs {
  team: string | undefined;
}
function Team({ team }: TeamAttrs) {
  if (team) {
    return (
      <span className="team">{team}</span>
    )
  }
  return <span></span>
}

interface DetailsAttr {
  experience: WorkExperience;
}
function Details({ experience }: DetailsAttr) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const descriptionRef = React.useRef<HTMLDivElement>(null);

  function toggleDescription(event: React.MouseEvent<HTMLDivElement>) {
    setShowFullDescription(!showFullDescription);
    const el = descriptionRef.current;
    if (el) {
      if (el.style.maxHeight.length > 0) {
        el.style.maxHeight = '';
      } else {
        el.style.maxHeight = el.scrollHeight + 'px';
      }
    }
  }

  return (
    <div className="company-entry" onClick={toggleDescription}>
      <div className="small-info">
        <div className="dates">
          <Dates startDate={experience.startDate}
            endDate={experience.endDate} />
        </div>
        <div className="location">
          <Location location={experience.location} />
        </div>
        <div className="jobTitle">
          <JobTitle title={experience.jobTitle} level={experience.jobLevel} />
        </div>
      </div>

      <div className="details">
        <h4><Team team={experience.team} /></h4>
        <ExpandableDescription
          ref={descriptionRef}
          shortDescription={experience.shortDescription}
          achievements={experience.achievements} />
      </div>
      <div className="accordion-icon">
        {showFullDescription ? '▲' : '▼'}
      </div>
    </div>
  );
}

interface CompanyExperiencesAttr {
  company: string;
  experiences: WorkExperience[];
}
function CompanyExperiences({ company, experiences }: CompanyExperiencesAttr) {
  return (
    <div className="column-item">
      <h3 className="company">{company}</h3>
      {experiences.map(experience => <Details experience={experience} />)}
    </div>
  );
}

function groupExperienceByCompany() {
  let workExperience = new Map<string, WorkExperience[]>();
  for (const experience of data) {
    if (workExperience.has(experience.company)) {
      workExperience.get(experience.company)!.push(experience);
      continue;
    }
    workExperience.set(experience.company, [experience]);
  }
  return workExperience;
}

function ExperienceData() {
  let formattedData = [];
  for (const [company, experiences] of groupExperienceByCompany()) {
    const formattedExperience = CompanyExperiences({ company, experiences });
    formattedData.push(formattedExperience);
  }
  return <div>{formattedData.map(item => item)}</div>;
}

function Experience() {
  return (
    <div className="experience column-container">
      <h2>Experience</h2>
      <ExperienceData />
    </div>
  );
}

export default Experience;
