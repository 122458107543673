import React, { useState } from 'react';
import { Dates, DateDetails } from './Dates';
import ExpandableDescription from './ExpandableDescription';
import './App.css';
import './Community.css';

interface CommunityExperience {
  title: string;
  role: string;
  startDate: DateDetails;
  endDate?: DateDetails;
  shortDescription: string;
  achievements: string[];
}

const data: CommunityExperience[] = require('./community.json');

interface DetailsAttr {
  experience: CommunityExperience;
}
function Details({ experience }: DetailsAttr) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const descriptionRef = React.useRef<HTMLDivElement>(null);

  function toggleDescription(event: React.MouseEvent<HTMLDivElement>) {
    setShowFullDescription(!showFullDescription);
    const el = descriptionRef.current;
    if (el) {
      if (el.style.maxHeight.length > 0) {
        el.style.maxHeight = '';
      } else {
        el.style.maxHeight = el.scrollHeight + 'px';
      }
    }
  }

  return (
    <div className="details-entry" onClick={toggleDescription}>
      <div className="small-info">
        <div className="dates">
          <Dates startDate={experience.startDate}
            endDate={experience.endDate} />
        </div>
      </div>

      <div className="details">
        <h4>{experience.role}</h4>
        <ExpandableDescription
          ref={descriptionRef}
          shortDescription={experience.shortDescription}
          achievements={experience.achievements} />
      </div>
      <div className="accordion-icon">
        {showFullDescription ? '▲' : '▼'}
      </div>
    </div>
  );
}

interface TitleExperienceAttr {
  title: string;
  experiences: CommunityExperience[];
}
function TitleExperience({ title, experiences }: TitleExperienceAttr) {
  return (
    <div className="column-item">
      <h3 className="title">{title}</h3>
      {experiences.map(experience => <Details experience={experience} />)}
    </div>
  );
}

function groupExperienceByTitle() {
  let communityExperience = new Map<string, CommunityExperience[]>();
  for (const experience of data) {
    if (communityExperience.has(experience.title)) {
      communityExperience.get(experience.title)!.push(experience);
      continue;
    }
    communityExperience.set(experience.title, [experience]);
  }
  return communityExperience;
}

function CommunityData() {
  let formattedData = [];
  for (const [title, experiences] of groupExperienceByTitle()) {
    const formattedExperience = TitleExperience({ title, experiences });
    formattedData.push(formattedExperience);
  }
  return <div>{formattedData.map(item => item)}</div>
}

function Community() {
  return (
    <div className="community-contributions column-container">
      <h2>Community</h2>
      <CommunityData />
    </div>
  );
}

export default Community;